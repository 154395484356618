import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "loadingIndicator", "container", "list", "message", "newMessages" ]
  static classes = [ "me" ]
  static values = {
    uid: Number,
    url: String,
    lastId: Number,
    recipientId: Number
  }

  connect() {
    this.lastIdValue = NaN
    this.loading = false
    this.endReached = false
    this.buffer = 25
  }

  messagesLoaded(event) {
    this._hideLoadingIndicator()
    this._scrollDown("instant")
    this.containerTarget.classList.add("loaded")
  }

  scroll(event) {
    if (!this.endReached && !this.loading && this.containerTarget.scrollTop < (2 * this.buffer)) {
      this._load()
    } else {
      const scrolledToBottom = this.containerTarget.offsetHeight + this.containerTarget.scrollTop >= this.containerTarget.scrollHeight - this.buffer
      if (scrolledToBottom) {
        this._hideNewMessagesBanner()
      }
    }
  }

  scrollToBottom(event) {
    this._scrollDown("smooth")
  }

  submit(event) {
    this._scrollDown("smooth")
  }

  messageTargetConnected(message) {
    const messageUID = Number(message.dataset.uid)
    const messageID = Number(message.dataset.id)

    if (isNaN(this.lastIdValue) || messageID < this.lastIdValue) {
      this.lastIdValue = messageID
    }
    if (messageUID == this.uidValue) {
      message.classList.add(this.meClass)
    }

    const scrolledToBottom = this.containerTarget.offsetHeight + this.containerTarget.scrollTop >= this.containerTarget.scrollHeight - message.offsetHeight - this.buffer
    if (scrolledToBottom) {
      // if we are (almost) at the bottom => stay at the bottom
      this._scrollDown("smooth")
    } else if (message.classList.contains("broadcasted")) {
      // if not but this is a broadcasted message => alert the user
      this._showNewMessagesBanner()
    }
  }

  _showLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove("hidden")
  }

  _hideLoadingIndicator() {
    this.loadingIndicatorTarget.classList.add("hidden")
  }

  _showNewMessagesBanner() {
    this.newMessagesTarget.classList.remove("hidden")
  }

  _hideNewMessagesBanner() {
    this.newMessagesTarget.classList.add("hidden")
  }

  _scrollDown(behavior) {
    this.containerTarget.scrollTo({
      left: 0,
      top: this.containerTarget.scrollHeight,
      behavior: behavior
    })
  }

  _load() {
    this.loading = true
    this._showLoadingIndicator()
    let path = `${this.urlValue}?last_id=${this.lastIdValue}`
    if (this.recipientIdValue) {
      path += `&private_chat_recipient_id=${this.recipientIdValue}`
    }
    fetch(path)
      .then(response => response.text())
      .then(html => {
        const scrollHeightBefore = this.containerTarget.scrollHeight
        this.listTarget.insertAdjacentHTML('beforeend', html)
        const scrollHeightAfter = this.containerTarget.scrollHeight
        this.containerTarget.scrollTop = scrollHeightAfter - scrollHeightBefore
        this._hideLoadingIndicator()
        this.loading = false
        if (html.trim() === "") {
          this.endReached = true
        }
      })
  }
}
